<div class="container py-4">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.setup.smsVerify.title' | translate }}</h4>
        </div>

        <div class="card-body">
          <div class="text-center mb-4">
            <h6 class="mb-0">
              {{ 'twoFactor.setup.smsVerify.description' | translate }}
            </h6>
            <p class="text-white-50">
              <strong>{{ formattedPhone }}</strong>
            </p>
            <div class="mt-2">
              <i class="material-icons text-light" style="font-size: 48px;">smartphone</i>
            </div>
          </div>

          <form [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="smsInput" class="sr-only">
                {{ 'twoFactor.setup.smsVerify.code' | translate }}
              </label>
              <input
                fsAutofocus
                type="text"
                id="smsInput"
                class="form-control form-control-lg text-center"
                formControlName="code"
                [placeholder]="'twoFactor.setup.smsVerify.code' | translate"
                [class.is-invalid]="showError"
                (input)="formatInput($event)"
                autocomplete="off"
                inputmode="numeric"
                pattern="[0-9]*"
              >
            </div>

            <!-- Error Message -->
            <div
              *ngIf="showError"
              class="alert alert-danger d-flex align-items-center mt-3"
              role="alert"
            >
              <i class="material-icons mr-2">error_outline</i>
              <div>{{ 'twoFactor.setup.smsVerify.error' | translate }}</div>
            </div>

            <div class="d-grid gap-2 mt-4">
              <button
                type="submit"
                class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                [disabled]="!verificationForm.valid || isSubmitting || isSubmittingResend"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                {{ 'twoFactor.setup.smsVerify.button' | translate }}
              </button>
            </div>
          </form>

          <div class="mt-4 text-center">
            <div class="mb-3">
              <button
                class="btn btn-link text-decoration-none p-0"
                [disabled]="resendCountdown > 0 || isSubmittingResend"
                (click)="resendCode()"
              >
                {{ 'twoFactor.setup.smsVerify.resend' | translate }}
                <span *ngIf="resendCountdown > 0">
                  ({{ resendCountdown }}s)
                </span>
              </button>
            </div>

            <a
              routerLink="/setup/mfa/sms"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <i class="material-icons align-middle mr-1" style="font-size: 16px;">arrow_back</i>
              {{ 'twoFactor.setup.smsVerify.changeNumber' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
