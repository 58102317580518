import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, interval, Subscription } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-mfa-totp',
  templateUrl: './mfa-totp.component.html',
  styleUrl: './mfa-totp.component.scss',
})
export class MfaTOTPComponent implements OnInit, OnDestroy {
  totpForm: FormGroup;
  isSubmitting = false;
  showError = false;
  remainingTime = 30;
  timerSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    if (this.userService.testIsFullyAuthenticated()) {
      this.userService.handleUserLoginChecks(this.userService.getCurrentUser());
      return;
    }
  }

  ngOnInit(): void {
    this.totpForm = this.fb.group({
      code: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    });

    // Start 30-second countdown timer
    this.startTimer();

    // Auto-submit when 6 digits are entered
    this.totpForm.get('code').valueChanges.subscribe((value) => {
      if (value.length === 6) {
        this.onSubmit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  startTimer(): void {
    // Calculate initial remaining time based on current seconds
    const now = new Date();
    this.remainingTime = 30 - (now.getSeconds() % 30);

    this.timerSubscription = interval(1000)
      .pipe(
        map(() => {
          const seconds = new Date().getSeconds();
          this.remainingTime = 30 - (seconds % 30);

          // Clear error when code expires
          if (this.remainingTime === 30 && !this.isSubmitting) {
            this.showError = false;
            this.totpForm.patchValue({ code: '' });
          }

          return this.remainingTime;
        }),
      )
      .subscribe();
  }

  formatInput(event: any): void {
    const input = event.target;
    let value = input.value.replace(/\D/g, '').substring(0, 6);
    input.value = value;
    this.totpForm.patchValue({ code: value }, { emitEvent: true });
  }

  async onSubmit(): Promise<void> {
    if (!this.totpForm.valid || this.isSubmitting) return;

    this.isSubmitting = true;
    this.showError = false;

    const code = this.totpForm.get('code').value;

    // Set the session update status
    this.userService.setUpdatingSessionStatus(true);

    // Wait for the token refresh lock
    from(this.userService.getRefreshTokenLock())
      .pipe(
        // Execute TOTP code challenge
        switchMap(() => this.userService.mfaChallengeTotpVerify(code)),
        // Store the updated JWT token
        tap((data) => this.userService.setAuth(data)),
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
        // And set the session update status
        finalize(() => this.userService.setUpdatingSessionStatus(false)),
      )
      .subscribe({
        next: (user) => {
          // Advance to the dashboard or onboarding if needed
          this.userService.handleUserLoginChecks(user);
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.status === 403) {
            this.showError = true;
            this.totpForm.patchValue({ code: '' });
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
  }
}
