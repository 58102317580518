<div class="container py-4">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.setup.totp.title' | translate }}</h4>
        </div>

        <div class="card-body text-left">
          <div class="text-center mb-4">
            <p class="mb-0">
              {{ 'twoFactor.setup.totp.description' | translate }}
            </p>
            <div class="qr-code-container mt-2">
              <canvas #qrCodeCanvas></canvas>
            </div>
          </div>

          <form [formGroup]="totpForm" (ngSubmit)="onSubmit()">
            <div class="form-group text-center">
              <label for="totpInput">
                {{ 'twoFactor.setup.totp.enterCode' | translate }}
              </label>
              <input
                type="text"
                id="totpInput"
                class="form-control form-control-lg text-center"
                formControlName="code"
                placeholder="{{ 'twoFactor.setup.totp.code' | translate }}"
                [class.is-invalid]="showError"
                (input)="formatInput($event)"
                autocomplete="off"
                inputmode="numeric"
                pattern="[0-9]*"
              >
            </div>

            <!-- Error Message -->
            <div
              *ngIf="showError"
              class="alert alert-danger d-flex align-items-center mt-3"
              role="alert"
            >
              <i class="material-icons mr-2">error_outline</i>
              <div>{{ 'twoFactor.setup.totp.error' | translate }}</div>
            </div>

            <div class="d-grid gap-2 mt-4">
              <button
                type="submit"
                class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                [disabled]="!totpForm.valid || isSubmitting"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                {{ 'twoFactor.setup.totp.button' | translate }}
              </button>
            </div>
          </form>

          <div class="mt-4 text-center">
            <a
              [routerLink]="!user.userProfileDTO.twoFactorAuthentication ? '/setup/mfa' : '/dashboard/profile'"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <i class="material-icons align-middle mr-1" style="font-size: 16px;">arrow_back</i>
              {{ 'twoFactor.setup.back' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
