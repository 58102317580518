import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from 'src/app/shared/services/api-error-handler.service';
import { from } from 'rxjs';

@Component({
  selector: 'fs-mfa-recovery-code',
  templateUrl: './mfa-recovery-code.component.html',
  styleUrl: './mfa-recovery-code.component.scss',
})
export class MfaRecoveryCodeComponent implements OnInit {
  recoveryForm: FormGroup;
  isSubmitting = false;
  showError = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    if (this.userService.testIsFullyAuthenticated()) {
      this.userService.handleUserLoginChecks(this.userService.getCurrentUser());
      return;
    }
  }

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      recoveryCode: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
          ),
        ],
      ],
    });
  }

  formatCode(event: Event): void {
    const input = event.target as HTMLInputElement;
    // Remove all non-alphanumeric characters
    let value = input.value.replace(/[^0-9a-fA-F]/g, '');

    // Truncate to max GUID length
    value = value.slice(0, 36);

    // Insert hyphens at correct positions
    const formattedValue = this.insertHyphens(value);

    // Update form control value
    this.recoveryForm.patchValue(
      { recoveryCode: formattedValue.toLowerCase() },
      { emitEvent: true },
    );
  }

  private insertHyphens(value: string): string {
    const segments = [
      value.slice(0, 8),
      value.slice(8, 12),
      value.slice(12, 16),
      value.slice(16, 20),
      value.slice(20),
    ];

    return segments
      .filter((segment) => segment.length > 0)
      .join('-')
      .slice(0, 36);
  }

  async onSubmit(): Promise<void> {
    if (!this.recoveryForm.valid || this.isSubmitting) return;

    this.isSubmitting = true;
    this.showError = false;

    const code = this.recoveryForm.get('recoveryCode').value;

    // Set the session update status
    this.userService.setUpdatingSessionStatus(true);

    // Wait for the token refresh lock
    from(this.userService.getRefreshTokenLock())
      .pipe(
        // Execute recovery code challenge
        switchMap(() => this.userService.mfaChallengeRecoveryCodeVerify(code)),
        // Store the updated JWT token
        tap((data) => this.userService.setAuth(data)),
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
        // And set the session update status
        finalize(() => this.userService.setUpdatingSessionStatus(false)),
      )
      .subscribe({
        next: (user) => {
          // Advance to the dashboard or onboarding if needed
          this.userService.handleUserLoginChecks(user);
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.status === 403) {
            this.showError = true;
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
  }
}
