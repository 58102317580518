<div class="container py-4">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.challenge.totp.title' | translate }}</h4>
        </div>

        <div class="card-body">
          <div class="text-center mb-4">
            <h6>
              {{ 'twoFactor.challenge.totp.description' | translate }}
            </h6>
            <div class="timer-circle mb-3">
              <span class="timer-number">{{ remainingTime }}</span>
              <svg class="timer-svg" viewBox="0 0 36 36">
                <path
                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#e9ecef"
                  stroke-width="3"
                />
                <path
                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#007bff"
                  stroke-width="3"
                  [attr.stroke-dasharray]="(remainingTime / 30) * 100 + ', 100'"
                />
              </svg>
            </div>
          </div>

          <form [formGroup]="totpForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="totpInput" class="sr-only">
                {{ 'twoFactor.challenge.totp.code' | translate }}
              </label>
              <input
                fsAutofocus
                type="text"
                id="totpInput"
                class="form-control form-control-lg text-center"
                formControlName="code"
                [placeholder]="'twoFactor.challenge.totp.code' | translate"
                [class.is-invalid]="showError"
                (input)="formatInput($event)"
                autocomplete="off"
                inputmode="numeric"
                pattern="[0-9]*"
              >
            </div>

            <!-- Error Message -->
            <div
              *ngIf="showError"
              class="alert alert-danger d-flex align-items-center mt-3"
              role="alert"
            >
              <i class="material-icons mr-2">error_outline</i>
              <div>{{ 'twoFactor.challenge.totp.error' | translate }}</div>
            </div>

            <div class="d-grid gap-2 mt-4">
              <button
                type="submit"
                class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                [disabled]="!totpForm.valid || isSubmitting"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                {{ 'twoFactor.challenge.totp.button' | translate }}
              </button>
            </div>
          </form>

          <div class="mt-4 text-center">
            <a
              routerLink="/mfa-challenge/options"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <i class="material-icons align-middle mr-1" style="font-size: 16px;">arrow_back</i>
              {{ 'twoFactor.challenge.back' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
