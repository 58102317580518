import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, interval, Subscription } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';
import { UserDetails } from '../../shared/models/userdetails.model';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { formatPhoneNumber } from '../../shared/utils/phone-number';

@Component({
  selector: 'fs-mfa-sms',
  templateUrl: './mfa-sms.component.html',
  styleUrl: './mfa-sms.component.scss',
})
export class MfaSmsComponent implements OnInit, OnDestroy {
  smsForm: FormGroup;
  user: UserDetails;
  isSubmitting = false;
  isSubmittingResend = false;
  showError = false;
  resendCountdown = 0;
  countdownSubscription: Subscription;
  phone: string;
  formattedPhone: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    this.user = this.userService.getCurrentUser();

    if (this.userService.testIsFullyAuthenticated()) {
      this.userService.handleUserLoginChecks(this.user);
      return;
    }

    if (!this.user?.userProfileDTO) {
      this.router.navigateByUrl('/');
      return;
    }

    this.phone = this.user.userProfileDTO.phone;
    this.formattedPhone = formatPhoneNumber(this.phone);
  }

  ngOnInit(): void {
    this.smsForm = this.fb.group({
      code: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    });

    // Start with initial countdown
    this.startResendCountdown();

    // Auto-submit when 6 digits are entered
    this.smsForm.get('code').valueChanges.subscribe((value) => {
      if (value.length === 6) {
        this.onSubmit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  formatInput(event: any): void {
    const input = event.target;
    let value = input.value.replace(/\D/g, '').substring(0, 6);
    input.value = value;
    this.smsForm.patchValue({ code: value }, { emitEvent: true });
  }

  startResendCountdown(): void {
    this.resendCountdown = 30;

    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    this.countdownSubscription = interval(1000)
      .pipe(
        map(() => {
          if (this.resendCountdown > 0) {
            this.resendCountdown--;
          }
          return this.resendCountdown;
        }),
      )
      .subscribe();
  }

  async resendCode(): Promise<void> {
    if (
      this.resendCountdown > 0 ||
      this.isSubmitting ||
      this.isSubmittingResend
    )
      return;

    this.isSubmittingResend = true;

    this.userService.mfaChallengeSmsInit().subscribe({
      next: () => {
        this.smsForm.reset();
        this.showError = false;
        this.isSubmittingResend = false;
        this.startResendCountdown();
      },
      error: (err) => {
        this.isSubmittingResend = false;
        this.apiErrorHandlerService.getHandler().handle(err);
      },
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.smsForm.valid || this.isSubmitting) return;

    this.isSubmitting = true;
    this.showError = false;

    const code = this.smsForm.get('code').value;

    // Set the session update status
    this.userService.setUpdatingSessionStatus(true);

    // Wait for the token refresh lock
    from(this.userService.getRefreshTokenLock())
      .pipe(
        // Execute SMS code challenge
        switchMap(() => this.userService.mfaChallengeSmsVerify(code)),
        // Store the updated JWT token
        tap((data) => this.userService.setAuth(data)),
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
        // And set the session update status
        finalize(() => this.userService.setUpdatingSessionStatus(false)),
      )
      .subscribe({
        next: (user) => {
          // Advance to the dashboard or onboarding if needed
          this.userService.handleUserLoginChecks(user);
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.status === 403) {
            this.showError = true;
            this.smsForm.patchValue({ code: '' });
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
  }
}
